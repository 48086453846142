// Block includes the done / undone marker or sign up depending on login status
.share-block {
    .button {
        max-width: $max-width-mobile;
        margin-left: auto;
        margin-right: auto;
        margin-top: $width-gap;
    }

    .button-undone {
        border-color: $color-success;

        &:hover,
        &:focus {
            background-color: $color-success;
        }
    }

    .button-done {
        border-color: $color-success;
        background-color: $color-success;
        color: #fff;

        &:hover,
        &:focus {
            background-color: $color-row-bg;
            color: $color-text;
        }
    }
}

.share-block__share {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    align-items: center;
    margin-top: $width-gap;
    font-family: $headers-font;

    a {
        background-color: $color-primary;
        display: block;
        width: 40px;
        height: 40px;
        margin-left: 16px;
        border-radius: 6px;
    }

    svg {
        position: relative;
        @extend %icon-svg;
        fill: $color-row-bg;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.share-block_is-done {
    max-width: $max-width-medium-pages;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
