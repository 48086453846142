.form__extra-links {
    border-top: 5px $color-line solid;

    .container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .button,
    .btn,
    button,
    a {
        margin: $width-gap*1.5 $width-gap;
        display: inline-block;
        margin-bottom: 0;

        @media (max-width: 767px) {
            margin: $width-gap 0.5rem 0 0.5rem;

            &:last-child {
                margin-right: 0;
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }
}
