// Tags
.tags-block {
    text-transform: uppercase;
    font-size: 0.875rem;
    letter-spacing: 1px;

    @media (min-width: 768px) {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }

    h2 {
        color: $color-text;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
    }

    ul {
        @extend %reset-list;
        display: flex;
        flex-wrap: wrap;
    }
}

.tags-block__header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: $width-gap * 0.5;

    svg {
        @extend %icon-svg;
        position: relative;
        left: 0.35rem;
        top: 1px;
        fill: $color-primary;
        margin-right: $width-gap * 0.75;
    }
}

.tags-block__tag {
    background-color: $color-row-bg;
    padding: $width-gap * 0.25 $width-gap * 0.5;

    &,
    &:first-child,
    &:last-child {
        margin: $width-gap * 0.125;
    }
}

// Author & Published date detail
.author-date-block {
    margin-top: $width-gap * 1.5;
    border-top: 1px $color-line solid;
    border-bottom: 1px $color-line solid;
    display: flex;
    justify-content: flex-start;

    p {
        margin: 0;
        font-size: 0.875rem;
    }

    > div {
        width: 50%;
        padding: $width-gap * 0.75;

        &:last-child:not(:first-child) {
            @media (min-width: 768px) {
                border-left: 1px $color-line solid;
            }
        }

        @media (max-width: 599px) {
            padding: $width-gap*0.5 $width-gap*0.25;
        }
    }
}

.author-date-block__title {
    font-family: $headers-font;
    font-weight: 600;
    text-transform: uppercase;
}
