body {
    &.reload-fade {
        opacity: 0.35;
        transition: opacity 0.3s ease-in-out;
    }
}

main {
    overflow: hidden;
}

section,
.section {
    margin-top: $width-gap * 2;
    margin-bottom: $width-gap;

    @media (max-width: 767px) {
        margin-top: $width-gap * 1.5;
        margin-bottom: $width-gap * 0.5;
    }

    &.row__background-grey,
    &[class*=column-] {
        @media (max-width: 767px) {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.row {
    display: flex;
    flex-flow: column nowrap;

    @media all and (min-width: 768px) {
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-start;

        width: calc(100% + #{$width-column-gap});
        margin-left: -#{$width-column-gap/2};
    }

    @media all and (max-width: 767px) {
        div[class*=column-] + div[class*=column-] {
            margin-top: $width-gap * 2;
        }
    }
}

.row__background-grey {
    position: relative;
    background-color: $color-row-bg;
    padding: $width-gap*1.5 0 $width-gap*2 0;

    // Outer version has background & extended edges only on desktop
    @media (max-width: 767px) {
        padding: $width-gap*1.25 0;

        &.row_background-desktop {
            padding: 0;
            background-color: transparent;

            &:after,
            &:before {
                display: none;
            }
        }
    }

    &:after,
    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 99999px;
        width: 50vw;
        z-index: -1;
        background-color: $color-row-bg;
    }

    &:after {
        left: 100%;
    }

    &:before {
        left: -99999px;
        left: -50vw;
    }

    // Inner version has background & extended edges only on mobile
    .row__background-grey {
        padding: 0;

        @media (max-width: 767px) {
            background-color: $color-row-bg;
            padding: $width-gap 0;
        }

        @media (min-width: 768px) {
            &:after,
            &:before {
                display: none;
            }
        }
    }
}

@media all and (min-width: 768px) {
    .column-1 { width: calc((1/12*100%) - #{$width-column-gap}) }
    .column-2 { width: calc((2/12*100%) - #{$width-column-gap}) }
    .column-3 { width: calc((3/12*100%) - #{$width-column-gap}) }
    .column-4 { width: calc((4/12*100%) - #{$width-column-gap}) }
    .column-5 { width: calc((5/12*100%) - #{$width-column-gap}) }
    .column-6 { width: calc((6/12*100%) - #{$width-column-gap}*1.5) }
    .column-7 { width: calc((7/12*100%) - #{$width-column-gap}) }
    .column-8 { width: calc((8/12*100%) - #{$width-column-gap}) }
    .column-9 { width: calc((9/12*100%) - #{$width-column-gap}) }
    .column-10 { width: calc((10/12*100%) - #{$width-column-gap}) }
    .column-11 { width: calc((11/12*100%) - #{$width-column-gap}) }
    .column-12 { width: calc((12/12*100%) - #{$width-column-gap}) }

    div[class*=column-] {
        margin: 0 $width-column-gap/2;
    }
}

main > .container:first-of-type {
    margin-top: $width-column-gap;
}

.header__plus-link {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    a {
        font-family: $headers-font;
        font-size: 0.875rem;

        @media (max-width: 767px) {
            margin-bottom: 1rem;
        }
    }

    @media (max-width: 767px) {
        h2,
        .h2 {
            margin-top: 0;

            @media (max-width: 767px) {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}
