/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

html.medium {
  font-size: 18px; }

html.large {
  font-size: 20px; }

body {
  font-family: "Open Sans", sans-serif, sans-serif;
  line-height: 1.5;
  color: #1f1f1f; }

h1, .h1 {
  font-size: 4rem;
  line-height: 1.275;
  margin-top: 1.2em;
  margin-bottom: 1em; }

h2, .h2 {
  font-size: 1rem;
  line-height: 1.275;
  margin-top: 1em;
  margin-bottom: 1em; }

h3, .h3 {
  font-size: 1.5rem;
  line-height: 1.275;
  margin-top: 1.33em;
  margin-bottom: 0.66em; }

h4, .h4 {
  font-size: 1.25rem;
  line-height: 1.2;
  margin-top: 1.2em;
  margin-bottom: 0.8em; }

h5, .h5 {
  font-size: 1.1rem;
  line-height: 1.1;
  margin-top: 1.125em;
  margin-bottom: 0.9em; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1;
  margin-top: 1.5em;
  margin-bottom: 0.5em; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: 600;
  font-family: 'Cabin', sans-serif; }
  h1.inverted, .h1.inverted,
  h2.inverted, .h2.inverted,
  h3.inverted, .h3.inverted,
  h4.inverted, .h4.inverted,
  h5.inverted, .h5.inverted,
  h6.inverted, .h6.inverted {
    color: #ffffff; }
  h1.text-color, .h1.text-color,
  h2.text-color, .h2.text-color,
  h3.text-color, .h3.text-color,
  h4.text-color, .h4.text-color,
  h5.text-color, .h5.text-color,
  h6.text-color, .h6.text-color {
    color: #1f1f1f; }

.center {
  text-align: center; }

@media all and (max-width: 767px) {
  h1, .h1 {
    font-size: 2.25rem;
    margin-top: 1.5em;
    margin-bottom: 0.5em; } }

h2, .h2 {
  color: #757575;
  text-transform: uppercase;
  letter-spacing: 1.2px; }
  @media (max-width: 767px) {
    h2, .h2 {
      max-width: 458px;
      margin-left: auto;
      margin-right: auto; } }

@media all and (max-width: 767px) {
  .line-break__mobile {
    display: block; } }

a {
  color: inherit; }

hr {
  border: none;
  height: 3px;
  background: #1f1f1f; }

.sr__text,
.sr__input,
.sr__label {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

.focus-visible {
  position: absolute; }
  .focus-visible:not(:focus) {
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px); }

.sr__text,
.sr__input,
.sr__label {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

:focus {
  outline: none; }

body.user-tabbing a:focus,
body.user-tabbing button:focus,
body.user-tabbing .btn:focus,
body.user-tabbing .button:focus,
body.user-tabbing input:focus {
  box-shadow: 0 0 0.125rem 0.125rem #E9A065; }

body.user-tabbing .image-upload__container:focus, body.user-tabbing .image-upload__container:focus-within {
  box-shadow: 0 0 0.125rem 0.125rem #E9A065; }

body.user-tabbing form input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='button']):focus-within,
body.user-tabbing form textarea:focus-within,
body.user-tabbing form select:focus-within,
body.user-tabbing form button:focus-within {
  box-shadow: none;
  border-color: #E9A065; }

body.user-tabbing form label.radio:focus-within {
  box-shadow: 0 0 0.125rem 0.125rem #E9A065; }

body.user-tabbing form .checkbox:focus .custom-checkbox {
  box-shadow: 0 0 0.125rem 0.125rem #E9A065; }

body.user-tabbing form:focus {
  box-shadow: 0 0 0.125rem 0.125rem #E9A065; }

.footer__main ul, .nav__mobile-footer ul,
.nav__primary ul, .social, .slider ul, form .alert-block ul, form .errorlist, .snippet-list-block, .tags-block ul, .course__resources-block ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.theme__slider-link a, .snippets__featured .course-resource__card {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12); }

.nav__mobile-footer svg,
.nav__primary svg, .hero__course-resource svg, .course-resource__parts-bookmarks svg, .tags-block__header svg, .course-resource__detail-bookmark svg, .share-block__share svg {
  display: inline-block;
  width: 21px;
  height: 21px; }

.slider__buttons button:focus {
  outline: none;
  box-shadow: 0 0 10px #E9A065;
  border: 3px #E9A065 solid !important;
  z-index: 1; }

.course-resource__done-indicator:before, .course__resources-block .course-resource__done-date:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.4rem;
  width: 12px;
  height: 5px;
  border-bottom: 2px #5FAD56 solid;
  border-left: 2px #5FAD56 solid;
  transform: rotate(-45deg); }

.hidden {
  display: none; }

.row {
  display: flex;
  flex-flow: column nowrap; }
  @media all and (min-width: 768px) {
    .row {
      flex-flow: row nowrap;
      align-items: center; } }

.container {
  width: 100%;
  max-width: 988px;
  margin: auto;
  padding: 0 24px;
  box-sizing: border-box; }
  @media (max-width: 399px) {
    .container {
      padding: 0 16px; } }
  main > .container:first-child {
    min-height: 30rem; }
    @media all and (max-width: 767px) {
      main > .container:first-child {
        min-height: 18rem; } }

.demo-announcement {
  padding: 0.75rem 0;
  background: #000;
  color: #fff;
  text-align: center; }

.page-header {
  padding-top: 60px; }
  @media all and (max-width: 767px) {
    .page-header {
      padding-top: 53px; } }

.page-header__buttons {
  margin-left: auto; }

body.reload-fade {
  opacity: 0.35;
  transition: opacity 0.3s ease-in-out; }

main {
  overflow: hidden; }

section,
.section {
  margin-top: 48px;
  margin-bottom: 24px; }
  @media (max-width: 767px) {
    section,
    .section {
      margin-top: 36px;
      margin-bottom: 12px; } }
  @media (max-width: 767px) {
    section.row__background-grey, section[class*=column-],
    .section.row__background-grey,
    .section[class*=column-] {
      margin-top: 0;
      margin-bottom: 0; } }

.row {
  display: flex;
  flex-flow: column nowrap; }
  @media all and (min-width: 768px) {
    .row {
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-start;
      width: calc(100% + 1.5rem);
      margin-left: -0.75rem; } }
  @media all and (max-width: 767px) {
    .row div[class*=column-] + div[class*=column-] {
      margin-top: 48px; } }

.row__background-grey {
  position: relative;
  background-color: #F5F5F5;
  padding: 36px 0 48px 0; }
  @media (max-width: 767px) {
    .row__background-grey {
      padding: 30px 0; }
      .row__background-grey.row_background-desktop {
        padding: 0;
        background-color: transparent; }
        .row__background-grey.row_background-desktop:after, .row__background-grey.row_background-desktop:before {
          display: none; } }
  .row__background-grey:after, .row__background-grey:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 99999px;
    width: 50vw;
    z-index: -1;
    background-color: #F5F5F5; }
  .row__background-grey:after {
    left: 100%; }
  .row__background-grey:before {
    left: -99999px;
    left: -50vw; }
  .row__background-grey .row__background-grey {
    padding: 0; }
    @media (max-width: 767px) {
      .row__background-grey .row__background-grey {
        background-color: #F5F5F5;
        padding: 24px 0; } }
    @media (min-width: 768px) {
      .row__background-grey .row__background-grey:after, .row__background-grey .row__background-grey:before {
        display: none; } }

@media all and (min-width: 768px) {
  .column-1 {
    width: calc((1/12*100%) - 1.5rem); }
  .column-2 {
    width: calc((2/12*100%) - 1.5rem); }
  .column-3 {
    width: calc((3/12*100%) - 1.5rem); }
  .column-4 {
    width: calc((4/12*100%) - 1.5rem); }
  .column-5 {
    width: calc((5/12*100%) - 1.5rem); }
  .column-6 {
    width: calc((6/12*100%) - 1.5rem*1.5); }
  .column-7 {
    width: calc((7/12*100%) - 1.5rem); }
  .column-8 {
    width: calc((8/12*100%) - 1.5rem); }
  .column-9 {
    width: calc((9/12*100%) - 1.5rem); }
  .column-10 {
    width: calc((10/12*100%) - 1.5rem); }
  .column-11 {
    width: calc((11/12*100%) - 1.5rem); }
  .column-12 {
    width: calc((12/12*100%) - 1.5rem); }
  div[class*=column-] {
    margin: 0 0.75rem; } }

main > .container:first-of-type {
  margin-top: 1.5rem; }

.header__plus-link {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; }
  .header__plus-link a {
    font-family: "Cabin", sans-serif;
    font-size: 0.875rem; }
    @media (max-width: 767px) {
      .header__plus-link a {
        margin-bottom: 1rem; } }
  @media (max-width: 767px) {
    .header__plus-link h2,
    .header__plus-link .h2 {
      margin-top: 0; } }
  @media (max-width: 767px) and (max-width: 767px) {
    .header__plus-link h2,
    .header__plus-link .h2 {
      margin-left: 0;
      margin-right: 0; } }

footer {
  margin-top: 48px;
  font-family: "Cabin", sans-serif;
  background: #A688B7;
  color: #fff;
  padding: 30px 0 4.8px 0; }

@media all and (max-width: 767px) {
  .footer__main {
    padding-bottom: 3.75rem; } }

.course-resource__footer .footer__main {
  padding-bottom: 2rem; }
  @media all and (max-width: 767px) {
    .course-resource__footer .footer__main {
      padding-bottom: 5.75rem; } }

.footer__main .row {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0; }
  .footer__main .row + .row {
    margin-top: 48px; }
    @media all and (max-width: 767px) {
      .footer__main .row + .row {
        margin-top: 30px; } }

@media all and (max-width: 767px) {
  .footer__main ul {
    margin-top: 24px; } }

.footer__main a {
  display: block;
  padding: 8.4px;
  text-decoration: none; }
  @media (max-width: 767px) {
    .footer__main a {
      padding: 6px; } }

.footer__main p,
.footer__main p:last-child {
  margin: 0 0 15.6px 0; }

.footer__logos {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 100%; }

.footer__useful-links ul {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  max-width: 100%;
  flex-wrap: wrap;
  margin-top: 24px; }
  @media (max-width: 499px) {
    .footer__useful-links ul {
      width: 280px; }
      @supports (flex-wrap: wrap) {
        .footer__useful-links ul {
          width: 100%; } } }

.footer__logos img:first-child {
  width: 150px;
  margin-right: 24px; }

.footer__logos img:last-child {
  width: 130px; }

@media all and (max-width: 399px) {
  .footer__logos img {
    max-width: 120px; } }

.footer__copyright,
.footer__credit {
  font-size: 0.75rem; }

nav a.active, nav a:hover, nav a:focus {
  color: #E9A065;
  box-shadow: 0;
  outline: 0; }
  nav a.active svg, nav a:hover svg, nav a:focus svg {
    fill: #E9A065 !important; }

.nav {
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  padding: 12px 0;
  background-color: #94317A;
  font-family: "Cabin", sans-serif; }
  .nav .container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  @media all and (max-width: 767px) {
    .nav {
      padding: 0.5rem 0; } }

#nav__after-header-links {
  display: none; }
  @media all and (max-width: 767px) {
    #nav__after-header-links {
      display: block; } }

.nav__desktop-top {
  display: flex;
  align-items: flex-end; }
  @media all and (max-width: 767px) {
    .nav__desktop-top {
      display: none; } }

.nav__mobile-footer {
  display: none; }
  @media all and (max-width: 767px) {
    .nav__mobile-footer {
      position: fixed;
      z-index: 10;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      background-color: #fff;
      border-top: #E4E4E4 solid 1px;
      height: 70px; }
      .nav__mobile-footer ul {
        width: 100%; }
      .nav__mobile-footer a {
        display: block;
        padding: 0.5rem 0.25rem;
        color: #757575;
        fill: #757575;
        fill-opacity: 0.9;
        text-decoration: none; }
        .nav__mobile-footer a:focus {
          position: relative;
          z-index: 1; }
      .nav__mobile-footer .nav__logged-in li {
        width: 25%; }
      .nav__mobile-footer li {
        width: 50%;
        text-align: center; }
      .nav__mobile-footer p {
        margin-top: 0;
        line-height: 1;
        font-size: 0.8125rem; } }
  @media (max-width: 400px) {
    .nav__mobile-footer li {
      width: auto !important;
      flex-grow: 1; } }

.nav__mobile-footer ul,
.nav__primary ul {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0; }

.nav__primary {
  flex-shrink: 0;
  position: relative;
  top: 2px; }
  .nav__primary a {
    display: block;
    padding: 5px 12px;
    text-decoration: none;
    color: #fff; }
    @media all and (max-width: 767px) {
      .nav__primary a {
        padding: 6px;
        font-size: 0.8125rem; } }
    .nav__primary a svg {
      fill: #ffffff; }
  @media all and (max-width: 767px) {
    .nav__primary {
      text-transform: uppercase; }
      .nav__primary svg {
        max-width: 18px; } }

.nav__brand,
.footer__brand {
  flex-shrink: 1;
  display: inline-block;
  position: relative;
  top: -1px; }
  @media (min-width: 400px) {
    .nav__brand,
    .footer__brand {
      top: -2px; } }

.footer__brand {
  margin-bottom: 24px; }

.nav__brand {
  width: 8.8125rem;
  padding-right: 1rem;
  display: flex;
  align-items: flex-end; }

.icon {
  width: 1.5em;
  height: 1.5em;
  fill: currentColor; }

.social {
  display: flex;
  flex-flow: row nowrap; }
  .social > li + li {
    margin-left: 24px; }

.button,
.btn,
button,
input[type="submit"] {
  display: block;
  width: 100%;
  padding: 12px;
  text-decoration: none;
  border: 3px solid #E9A065;
  background-color: transparent;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  cursor: pointer;
  color: #1f1f1f;
  text-align: center;
  font-family: "Cabin", sans-serif;
  max-width: 458px; }
  .button:first-child, .button:last-child,
  .btn:first-child,
  .btn:last-child,
  button:first-child,
  button:last-child,
  input[type="submit"]:first-child,
  input[type="submit"]:last-child {
    margin: 24px auto; }
    @media (max-width: 767px) {
      .button:first-child, .button:last-child,
      .btn:first-child,
      .btn:last-child,
      button:first-child,
      button:last-child,
      input[type="submit"]:first-child,
      input[type="submit"]:last-child {
        margin: 36px auto; } }
  .button:hover, .button:focus,
  .btn:hover,
  .btn:focus,
  button:hover,
  button:focus,
  input[type="submit"]:hover,
  input[type="submit"]:focus {
    background: #E9A065;
    color: #fff; }
  .button.secondary,
  .btn.secondary,
  button.secondary,
  input[type="submit"].secondary {
    background: #E9A065;
    color: #fff;
    border: none; }
    .button.secondary:hover, .button.secondary:focus,
    .btn.secondary:hover,
    .btn.secondary:focus,
    button.secondary:hover,
    button.secondary:focus,
    input[type="submit"].secondary:hover,
    input[type="submit"].secondary:focus {
      background: #eba872; }
  .button.tertiary,
  .btn.tertiary,
  button.tertiary,
  input[type="submit"].tertiary {
    text-transform: none;
    border: none;
    font-size: 0.935rem;
    color: #E9A065;
    background: none; }
  .button.btn-small,
  .btn.btn-small,
  button.btn-small,
  input[type="submit"].btn-small {
    padding: 6px;
    font-size: 0.875rem;
    max-width: 15rem; }

a.color-link {
  text-transform: uppercase;
  color: #E9A065;
  text-decoration: none; }

img,
video {
  width: 100%;
  height: auto;
  vertical-align: top; }

figure {
  margin: 0; }

figcaption {
  display: block;
  vertical-align: top;
  padding: 8px;
  background: #E9A065;
  color: #fff;
  font-size: 0.875rem; }

.page__logo {
  display: inline-block;
  width: 100%;
  height: 0;
  vertical-align: top;
  padding-bottom: 21.89%;
  background: url(../img/ruleoflife-logo.png) left center/contain no-repeat; }

.slider__multi-across {
  width: calc(100% + 0.5rem);
  position: relative;
  top: -0.25rem;
  left: -0.25rem; }
  @media (max-width: 767px) {
    .slider__multi-across ul {
      overflow: visible !important; } }
  .slider__multi-across li {
    padding: 6px;
    box-sizing: border-box; }
  @media (max-width: 499px) {
    .slider__multi-across {
      max-width: 458px; } }

.slider__buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 24px; }
  .slider__buttons button {
    margin: 0 1rem;
    width: 3rem;
    border-radius: 50%;
    position: relative;
    font-family: "Cabin", sans-serif;
    transform: scale(0.9); }
    .slider__buttons button.active {
      transform: scale(1);
      background-color: #E9A065;
      color: #fff; }
    .slider__buttons button:not(:last-child):after {
      content: '';
      position: absolute;
      left: 130%;
      top: calc(50% - 1px);
      width: 1rem;
      height: 2px;
      background-color: #E4E4E4; }

.slider__next, .slider__next:focus,
.slider__prev,
.slider__prev:focus {
  top: 3px;
  left: 3px;
  bottom: 4px;
  width: 35px;
  margin: 0 !important;
  z-index: 1;
  background: rgba(255, 255, 255, 0.9);
  color: #E9A065;
  border: none; }
  .slider__next:after, .slider__next:focus:after,
  .slider__prev:after,
  .slider__prev:focus:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15px;
    height: 15px;
    background-color: transparent;
    border-top: #E9A065 3px solid;
    border-left: #E9A065 3px solid;
    transform: translate(-25%, -50%) rotate(-45deg); }

.slider__next.hide-link,
.slider__prev.hide-link {
  display: none; }

.slider__next, .slider__next:focus {
  left: auto;
  right: 3px; }
  .slider__next:after, .slider__next:focus:after {
    transform: translate(-50%, -50%) rotate(135deg); }

.slider li {
  position: relative;
  transition: transform 0.3s ease-in-out; }
  .slider li[aria-hidden="true"] {
    transform: scaleY(0.96); }

.slider ul:after {
  content: '';
  display: block;
  clear: both; }

@media (min-width: 768px) {
  .slider ul.non-slide-desktop {
    display: flex;
    flex-wrap: wrap;
    margin-top: -0.125rem; }
    .slider ul.non-slide-desktop li {
      min-width: 25%;
      max-width: 50%;
      width: 33.33%;
      flex-grow: 1;
      flex-shrink: 1; }
      .slider ul.non-slide-desktop li:nth-last-child(-n+2):not(.no-remainder), .slider ul.non-slide-desktop li:nth-last-child(-n+4):not(.no-remainder):not(.remainder-two) {
        width: 50%; }
    .slider ul.non-slide-desktop .slider__next,
    .slider ul.non-slide-desktop .slider__prev {
      display: none; } }

.tabs__group.search__hide {
  opacity: 0; }

@media (min-width: 768px) {
  .tabs__group h1 {
    margin-top: 0.65em;
    margin-right: 1rem; } }

.tabs__group h2 {
  margin-bottom: 0; }

.tabs__content {
  display: block; }
  .tabs__content.tabs__hide {
    display: none; }

.tabs_buttons-holder {
  box-shadow: 0 9px 7px -4px rgba(0, 0, 0, 0.05);
  margin-bottom: 36px; }

.tabs__buttons {
  display: flex; }
  .tabs__buttons button {
    border: 3px solid white;
    color: #757575;
    margin: 0;
    font-size: 0.875rem;
    position: relative;
    box-sizing: border-box; }
    .tabs__buttons button:after {
      content: '';
      position: absolute;
      bottom: -4px;
      height: 3px;
      background-color: #E4E4E4;
      left: 0;
      width: 100%;
      display: none; }
    .tabs__buttons button[aria-expanded="true"] {
      color: #E9A065; }
      .tabs__buttons button[aria-expanded="true"]:after {
        display: block;
        background-color: #E9A065; }
    .tabs__buttons button:hover, .tabs__buttons button:focus {
      background-color: #fff; }
    .tabs__buttons button:focus {
      outline: none; }
      .tabs__buttons button:focus:after {
        display: block; }
      .tabs__buttons button:focus[aria-expanded="true"]:after {
        height: 4px; }

.tabs__full-page .tabs_buttons-holder {
  box-shadow: none; }
  @media (max-width: 767px) {
    .tabs__full-page .tabs_buttons-holder {
      margin-left: -0.75rem;
      width: calc(100% + 1.5rem); } }

.tabs__full-page button {
  width: auto; }
  .tabs__full-page button[aria-expanded="true"]:not(:focus):after {
    display: none; }

.tabs__flex-header {
  margin-bottom: 36px; }
  @media (min-width: 768px) {
    .tabs__flex-header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; } }

.tabs__courses .course-resource__progress {
  display: block;
  width: 100%;
  order: 4;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0; }

.tabs__courses .course-resource__card-info {
  flex-wrap: wrap; }

.tabs__courses .course-resource__text-snippet {
  width: 50%; }

#tabs-home {
  margin-top: 24px; }

.form__extra-links {
  border-top: 5px #E4E4E4 solid; }
  .form__extra-links .container {
    display: flex;
    justify-content: center;
    align-items: flex-start; }
  .form__extra-links .button,
  .form__extra-links .btn,
  .form__extra-links button,
  .form__extra-links a {
    margin: 36px 24px;
    display: inline-block;
    margin-bottom: 0; }
    @media (max-width: 767px) {
      .form__extra-links .button,
      .form__extra-links .btn,
      .form__extra-links button,
      .form__extra-links a {
        margin: 24px 0.5rem 0 0.5rem; }
        .form__extra-links .button:last-child,
        .form__extra-links .btn:last-child,
        .form__extra-links button:last-child,
        .form__extra-links a:last-child {
          margin-right: 0; }
        .form__extra-links .button:first-child,
        .form__extra-links .btn:first-child,
        .form__extra-links button:first-child,
        .form__extra-links a:first-child {
          margin-left: 0; } }

.messages {
  position: fixed;
  z-index: 9;
  top: 60px;
  left: 0;
  right: 0;
  margin: 0;
  background-color: #F5F5F5;
  font-family: "Cabin", sans-serif;
  font-size: 0.935rem;
  padding: 0;
  opacity: 0;
  transition: opacity 0.45s ease-in-out; }
  .messages.message-show {
    opacity: 1; }
  .messages li {
    list-style: none;
    text-align: center;
    padding: 0.5rem;
    padding-bottom: 0.35rem; }
  @media (max-width: 767px) {
    .messages {
      top: 53px; } }
  .messages .success {
    background-color: #5FAD56;
    color: #fff; }

.filter__holder {
  position: relative;
  margin-left: auto; }
  .filter__holder:last-child {
    margin-bottom: 1rem; }
  .filter__holder button {
    margin: 0 3px 0 0; }

.filter__form {
  position: absolute;
  right: 0;
  bottom: -3.5rem;
  z-index: 2;
  width: 290px; }
  .filter__form p,
  .filter__form form {
    margin: 0; }
  .filter__form label {
    display: none; }
  .filter__form select {
    border-color: #E9A065; }

.filter__toggle-show {
  display: flex;
  align-items: center;
  width: auto;
  border: none;
  color: #757575;
  letter-spacing: 1.2px;
  font-family: "Cabin", sans-serif;
  font-weight: 600;
  padding: 0.25rem; }
  .filter__toggle-show:hover, .filter__toggle-show:focus {
    background-color: #fff;
    color: #757575; }
  .filter__toggle-show[aria-expanded='false'] + .filter__form {
    display: none; }

.filter__ico-holder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 1.5rem;
  height: 20px;
  margin-left: 12px; }
  .filter__ico-holder:before, .filter__ico-holder:after {
    content: ''; }
  .filter__ico-holder:before, .filter__ico-holder:after,
  .filter__ico-holder span {
    display: block;
    width: 100%;
    height: 4px;
    background-color: #E9A065; }
  .filter__ico-holder span {
    transform: scaleX(0.65); }
  .filter__ico-holder:after {
    transform: scaleX(0.2); }

#search__input {
  padding: 1px;
  background-color: #94317A;
  color: #fff; }
  #search__input h1 {
    margin-bottom: 24px; }
  #search__input input {
    margin: 0 1rem 36px 1rem;
    padding: 0.5rem 0;
    background-color: transparent;
    border: none;
    border-bottom: 3px solid #fff;
    color: #fff;
    width: 100%;
    max-width: 600px; }
    #search__input input:focus {
      outline: none; }
    @media (max-width: 767px) {
      #search__input input {
        margin: 0 0 24px 0; } }
  #search__input ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #fff; }

#tabs-group-search {
  margin-top: 12px; }

#search__filters {
  transition: opacity 0.3s ease-in-out; }
  #search__filters.grey-out {
    opacity: 0.5; }
  #search__filters.search__hide {
    opacity: 0; }
  #search__filters .h2 {
    margin-top: 0;
    margin-bottom: 0.75rem; }
    @media (max-width: 767px) {
      #search__filters .h2 {
        max-width: none; } }

.search__results {
  max-width: 458px;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  min-height: 300px;
  transition: opacity 0.3s ease-in-out; }
  @media (min-width: 768px) {
    .search__results {
      max-width: 600px; } }
  .search__results.fade-out {
    opacity: 0; }
  .search__results.hide {
    display: none; }
  .search__results .course-resource__card {
    max-width: none;
    margin-bottom: 1rem; }
  .search__results a {
    display: block; }

.search__filter-holder {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  top: -0.5rem;
  width: calc(100% + 0.5rem);
  margin-left: -0.25rem;
  min-height: 2.5rem; }

.search__filter-button {
  width: auto;
  margin: 0.25rem !important;
  border-width: 2px;
  border-color: #F5F5F5;
  font-size: 0.875rem; }
  .search__filter-button.active {
    border-color: #5FAD56; }
  .search__filter-button:hover, .search__filter-button:focus {
    background-color: #F5F5F5;
    color: #1f1f1f; }

.search__pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .search__pagination button {
    width: calc(50% - 0.75rem);
    padding-left: 24px;
    padding-right: 24px;
    margin: 0.5rem 0.75rem 0.5rem 0; }
    .search__pagination button:last-child {
      margin: 0.5rem 0 0.5rem 0.75rem; }
    .search__pagination button:not(.show) {
      display: none; }

form {
  margin: 2rem 0 1rem 0; }
  form > .control-group,
  form .form__margin-top,
  form .control-group__full-width {
    margin-bottom: 24px;
    color: #1f1f1f; }
  form .control-group__full-width {
    width: 100%; }
    form .control-group__full-width .control-group + .control-group {
      margin-top: 12px; }
  @media all and (min-width: 768px) {
    .form__desktop-cols form {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      align-items: flex-start; }
      .form__desktop-cols form > .control-group,
      .form__desktop-cols form > .buttonHolder,
      .form__desktop-cols form > .form__rol-placeholder {
        width: calc(50% - 1.5rem); } }
  form .form__subsect {
    font-weight: bold;
    margin-top: 18px; }
    @media (max-width: 767px) {
      form .form__subsect {
        margin-top: 36px; } }
  form .control-group > label {
    display: block;
    font-family: 'Cabin', sans-serif;
    color: #757575;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-size: 0.935rem;
    margin-bottom: 0.5rem; }
    @media all and (max-width: 767px) {
      form .control-group > label {
        font-size: 0.875rem; } }
  form label.checkbox,
  form label.radio {
    padding-left: 2rem;
    position: relative;
    box-sizing: border-box;
    display: block; }
  form .custom-checkbox,
  form .custom-radio {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 1.35rem;
    height: 1.35rem;
    border: 3px solid #E4E4E4;
    margin-right: 0.25rem; }
    form .custom-checkbox:before,
    form .custom-radio:before {
      content: '';
      position: absolute;
      display: none; }
  form .custom-checkbox:before {
    top: 2px;
    height: 0.5rem;
    width: 0.2rem;
    border: 2px solid #1f1f1f;
    border-left: none;
    border-top: none;
    transform: translateX(0.35rem) rotate(45deg); }
  form .radio {
    display: block; }
    form .radio[for='id_id_font_size_0_1'] {
      margin-bottom: 0.5rem;
      font-size: 16px; }
    form .radio[for='id_id_font_size_0_2'] {
      font-size: 18px;
      margin-bottom: 0.5rem; }
    form .radio[for='id_id_font_size_0_3'] {
      font-size: 20px; }
  form .custom-radio {
    border-radius: 50%;
    margin-right: 0.5rem; }
    form .custom-radio:before {
      top: calc(50% - 0.25rem);
      left: calc(50% - 0.25rem);
      width: 0.5rem;
      height: 0.5rem;
      background-color: #E9A065;
      border-radius: 50%; }
  form input[type='checkbox'],
  form input[type='radio'] {
    position: absolute;
    left: -999999px; }
  form input[type='checkbox']:checked + .custom-checkbox:before,
  form input[type='radio']:checked + .custom-radio:before {
    display: block; }
  form input[type='checkbox']:focus + .custom-checkbox,
  form input[type='radio']:focus + .custom-radio {
    border-color: #E9A065; }
  form input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='file']),
  form textarea,
  form select {
    display: block;
    appearance: none;
    width: 100%;
    padding: 12px;
    border: 3px solid #E4E4E4;
    border-radius: 0.125rem;
    background: #fff;
    vertical-align: top;
    font-size: inherit;
    font-family: inherit;
    -webkit-font-smoothing: antialiased;
    line-height: normal;
    color: #1f1f1f; }
  form input::placeholder {
    color: lighter(#1f1f1f, 20%); }
  form input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: lighter(#1f1f1f, 35%); }
  form .profile-image__holder .control-group {
    position: absolute;
    left: -999999px; }
  form .profile-image__holder button {
    margin: 0.5rem 0; }
  form #profile-img__preview-holder {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #E4E4E4; }
  form .profile-image__custom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap; }
    @media (max-width: 399px) {
      form .profile-image__custom {
        justify-content: center; } }
  form .profile-image__img {
    position: relative;
    width: 140px;
    height: 140px;
    margin-right: 16px;
    background-color: #E4E4E4;
    background-image: url(../img/profile-default.png);
    background-size: contain; }
    form .profile-image__img img {
      max-width: 100%;
      max-height: 100%; }
    @media (max-width: 399px) {
      form .profile-image__img {
        margin-bottom: 1rem; } }
  form #profile-pic__help,
  form #profile-img__preview-holder {
    display: none; }
    form #profile-pic__help.show,
    form #profile-img__preview-holder.show {
      width: 100%;
      display: block; }
  form a:first-child {
    display: block;
    color: #1f1f1f;
    font-size: 0.935rem; }
    form a:first-child:not(.button) {
      margin-top: 12px; }
  form .alert-block ul {
    margin-bottom: 24px;
    border-left: #E9A065 5px solid;
    padding-left: 0.5rem; }
  form .help-inline,
  form .help-block {
    display: block;
    font-size: 0.875rem; }
  form .errorlist {
    margin-bottom: 24px;
    color: #E9A065;
    font-weight: bold; }

.half-width form {
  max-width: 494px !important; }
  .half-width form .submit.btn {
    margin-left: 0;
    margin-right: 0;
    max-width: 494px !important; }

.theme__slider-link a {
  background-color: #fff;
  display: block;
  padding: 16px;
  position: relative;
  border-radius: 6px;
  text-align: center; }
  .theme__slider-link a:focus {
    position: relative;
    z-index: 1; }

.theme__slider-link img {
  width: 100%;
  max-width: 80px;
  max-height: 80px; }

.hero,
.hero__course-resource {
  position: relative;
  padding: 40px 0 45px 0; }
  @media (min-width: 768px) {
    .hero,
    .hero__course-resource {
      padding: 80px 0 85px 0; } }
  @media (min-width: 1024px) {
    .hero,
    .hero__course-resource {
      padding: 100px 0 100px 0; } }
  .hero h1,
  .hero p,
  .hero .h2,
  .hero__course-resource h1,
  .hero__course-resource p,
  .hero__course-resource .h2 {
    color: #fff;
    max-width: 26rem; }
  .hero .h2,
  .hero__course-resource .h2 {
    padding: 0;
    font-size: 0.875rem; }
  .hero h1,
  .hero__course-resource h1 {
    margin: 0; }
  .hero p,
  .hero__course-resource p {
    font-family: "Cabin", sans-serif;
    font-weight: 400; }
  .hero span,
  .hero__course-resource span {
    display: block; }

.hero__background,
.hero__course-resource-background,
.hero__course-resource-background-mobile,
.hero__course-resource-background-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: #1f1f1f;
  background-size: cover;
  background-position: center; }

.hero__background {
  background-image: url(../img/hero-768.jpg); }
  @media (min-width: 768px) {
    .hero__background {
      background-image: url(../img/logged-in-hero-1920.jpg); } }
  .hero_bg-stretch .hero__background {
    bottom: -6rem; }

.hero__course-resource h1,
.hero__course-resource p {
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
  padding: 0; }

.hero__course-resource .course-resource__divider:after {
  background-color: #fff; }

.hero__course-resource svg {
  fill: #fff; }

.course-resource-hero__info {
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-size: 0.875rem; }
  .course-resource-hero__info span {
    line-height: 1.35; }

.hero__course-resource-background-overlay {
  background-color: rgba(0, 0, 0, 0.6); }

@media (max-width: 767px) {
  .hero__course-resource-background {
    display: none;
    background-image: none !important; } }

@media (min-width: 768px) {
  .hero__course-resource-background-mobile {
    display: none;
    background-image: none !important; } }

.snippet-list-block {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: stretch;
  top: -0.25rem;
  left: -0.25rem;
  width: calc(100% + 0.5rem); }
  @media (max-width: 767px) {
    .snippet-list-block {
      display: block; } }
  .snippet-list-block li {
    padding: 6px;
    box-sizing: border-box; }
    .snippet-list-block li:last-child .course-resource__card {
      margin-bottom: 0; }
    @media (min-width: 768px) {
      .snippet-list-block li {
        width: 33.33%;
        flex-shrink: 0;
        padding-bottom: 0; }
        .snippet-list-block li:nth-child(n + 4) {
          margin-top: 12px; }
        .snippet-list-block li:first-child:last-child, .snippet-list-block li:first-child:nth-last-child(2), .snippet-list-block li:last-child:nth-child(2) {
          width: 50%; } }

.course-resource__card {
  max-width: 458px;
  margin: 0 1rem 0 0;
  border-bottom: 1px #E4E4E4 solid;
  padding-bottom: 0.75rem;
  background-color: #fff;
  height: 100%; }
  @supports (flex-wrap: wrap) {
    .course-resource__card {
      display: flex;
      flex-direction: column;
      justify-content: space-between; } }
  @media (max-width: 767px) {
    .course-resource__card {
      height: auto; }
      .course-resource__card:last-child {
        margin: 0 auto 12px auto; } }
  .course-resource__card h4 {
    overflow: hidden;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.125rem;
    line-height: 1.35rem;
    max-height: 3.9825rem;
    flex-shrink: 0; }
  .course-resource__card span {
    font-size: 0.875rem; }
  .course-resource__card a {
    text-decoration: none;
    height: 100%;
    border-radius: 6px; }
  .snippets__featured .course-resource__card {
    margin: 0 auto 12px auto;
    border-radius: 6px;
    padding-bottom: 1rem; }

.course-resource__card-info {
  display: flex;
  align-items: flex-start; }
  .snippets__featured .course-resource__card-info {
    display: block; }

.course-resource__progress {
  display: none;
  padding: 1rem;
  font-family: "Cabin", sans-serif; }
  .course-resource__progress .progress-holder {
    width: 100%;
    height: 0.25rem;
    background-color: #E4E4E4;
    position: relative;
    border-radius: 0.25rem;
    overflow: hidden; }
  .course-resource__progress .progress-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform-origin: left top;
    background-color: #5FAD56; }
  .course-resource__progress .progress-labels {
    display: flex;
    justify-content: space-between;
    color: #757575;
    margin-top: 0.25rem; }
    .course-resource__progress .progress-labels :first-child {
      color: #5FAD56; }
  .snippets__featured.snippets__subscribed .course-resource__progress {
    display: block; }

.course-resource__text-overflow,
.course-resource__text-overflow p {
  position: relative; }
  .course-resource__text-overflow:after,
  .course-resource__text-overflow p:after {
    content: '...';
    position: absolute;
    bottom: 1px;
    right: 0;
    height: 1.35rem;
    width: 0.75rem;
    background-color: #fff;
    line-height: 1.35rem; }

.course-resource__text-overflow {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #fff; }
  .course-resource__text-overflow p {
    flex-grow: 1;
    margin-top: 0;
    padding-right: 0.75rem;
    line-height: 1.35rem;
    font-size: 0.875rem; }
    .course-resource__text-overflow p:after {
      content: '';
      z-index: 1; }

.course-resource__text-snippet {
  display: flex;
  flex-direction: column;
  height: 4.05rem;
  overflow: hidden;
  flex-grow: 1; }
  .snippets__featured .course-resource__text-snippet {
    margin: 1.25rem 1rem 0 1rem; }
  .snippets__subscribed .course-resource__text-snippet {
    margin-top: 0; }

.course-resource__card-image {
  width: 4.05rem;
  height: 4.05rem;
  background-color: #94317A;
  background-size: cover;
  background-position: center;
  border-radius: 0.25rem;
  margin-right: 12px;
  flex-shrink: 0;
  background-color: #221F72; }
  .snippets__featured .course-resource__card-image {
    width: 100%;
    height: 128px;
    border-radius: 0.25rem 0.25rem 0 0; }

.course-resource__parts-bookmarks {
  margin-top: 15.6px;
  color: #757575;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .course-resource__parts-bookmarks svg {
    position: relative;
    top: 0.5px;
    fill: #757575;
    fill-opacity: 0.6; }
  .snippets__featured .course-resource__parts-bookmarks {
    margin-left: 1rem;
    margin-right: 1rem; }

.course-resource__label,
.course-resource__bookmarks {
  display: flex;
  align-items: center; }

.course-resource__label {
  flex-wrap: wrap; }
  .course-resource__label span:not(.course-resource__divider) {
    padding: 0 0.5rem; }

.course-resource__bookmarks svg {
  fill: #fff;
  stroke: #E9A065;
  stroke-width: 8px;
  transform: scale(0.85); }

.course-resource__bookmarks .is-bookmarked svg,
.course-resource__bookmarks.is-bookmarked svg {
  fill: #E9A065;
  fill-opacity: 1; }

.course-resource__bookmarks a {
  line-height: 0; }

.course-resource__divider {
  width: 0.1rem;
  height: 1rem;
  position: relative;
  padding: 0 0.025rem; }
  .course-resource__divider:after {
    content: '';
    display: block;
    position: relative;
    left: -2px;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 4px;
    background-color: #757575;
    border-radius: 4px; }

.tags-block {
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 1px; }
  @media (min-width: 768px) {
    .tags-block {
      padding-left: 0.75rem;
      padding-right: 0.75rem; } }
  .tags-block h2 {
    color: #1f1f1f;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0; }
  .tags-block ul {
    display: flex;
    flex-wrap: wrap; }

.tags-block__header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 12px; }
  .tags-block__header svg {
    position: relative;
    left: 0.35rem;
    top: 1px;
    fill: #E9A065;
    margin-right: 18px; }

.tags-block__tag {
  background-color: #F5F5F5;
  padding: 6px 12px; }
  .tags-block__tag, .tags-block__tag:first-child, .tags-block__tag:last-child {
    margin: 3px; }

.author-date-block {
  margin-top: 36px;
  border-top: 1px #E4E4E4 solid;
  border-bottom: 1px #E4E4E4 solid;
  display: flex;
  justify-content: flex-start; }
  .author-date-block p {
    margin: 0;
    font-size: 0.875rem; }
  .author-date-block > div {
    width: 50%;
    padding: 18px; }
    @media (min-width: 768px) {
      .author-date-block > div:last-child:not(:first-child) {
        border-left: 1px #E4E4E4 solid; } }
    @media (max-width: 599px) {
      .author-date-block > div {
        padding: 12px 6px; } }

.author-date-block__title {
  font-family: "Cabin", sans-serif;
  font-weight: 600;
  text-transform: uppercase; }

.course-resource__detail-bookmark {
  font-size: 0.875rem;
  position: relative;
  margin-right: 0.5rem; }
  .course-resource__detail-bookmark svg {
    position: relative;
    top: 1px;
    fill: #757575; }
  .course-resource__detail-bookmark:not(.is-bookmarked) svg {
    fill: transparent;
    stroke-width: 8px;
    stroke: #999; }
  .course-resource__detail-bookmark a {
    display: block;
    padding: 4px 8px 4px 0; }

.course-resource__detail-progress .course-resource__progress {
  display: block;
  padding: 0;
  align-items: center;
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  margin-bottom: -1rem; }
  .course-resource__detail-progress .course-resource__progress h2 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    max-width: 780px; }
  .course-resource__detail-progress .course-resource__progress h2,
  .course-resource__detail-progress .course-resource__progress span {
    font-size: 0.8765rem; }
  @media (max-width: 599px) {
    .course-resource__detail-progress .course-resource__progress {
      order: 3;
      margin-right: 0;
      width: 100%;
      margin-top: 1rem; } }

.course-resource__done-indicator {
  position: relative;
  opacity: 0.35;
  letter-spacing: 1.2px; }
  .course-resource__done-indicator span {
    margin-left: 24px;
    text-transform: uppercase; }
  .course-resource__done-indicator a {
    text-decoration: none; }
  .course-resource__done-indicator:before {
    border-color: #757575; }

.course-resource__detail-top-done-message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #5FAD56;
  color: #fff;
  padding: 0.35rem 0 0.2rem 0;
  font-size: 0.875rem; }
  .course-resource__detail-top-done-message .course-resource__done-indicator {
    color: #fff;
    opacity: 1; }
    .course-resource__detail-top-done-message .course-resource__done-indicator:before {
      border-color: #fff; }
  .course-resource__detail-top-done-message .container {
    display: flex;
    justify-content: space-between; }

.course-resource__detail-footer {
  position: fixed;
  z-index: 3;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  background-color: rgba(245, 245, 245, 0.9);
  border-bottom: 1px #E4E4E4 solid;
  border-top: 1px #E4E4E4 solid;
  font-size: 0.875rem;
  padding: 2px 0;
  font-family: "Cabin", sans-serif; }
  @media (max-width: 767px) {
    .course-resource__detail-footer {
      bottom: 70px; } }
  .course-resource__detail-footer.space-between .container {
    justify-content: space-between; }
  .course-resource__detail-footer.is_done .course-resource__done-indicator {
    opacity: 1;
    color: #5FAD56; }
    .course-resource__detail-footer.is_done .course-resource__done-indicator:before {
      border-color: #5FAD56; }
  .course-resource__detail-footer .container {
    display: flex;
    align-items: center; }
  .course-resource__detail-footer .footer-divider {
    display: block;
    width: 1px;
    height: 34px;
    margin-left: 0.5rem;
    background-color: #E4E4E4; }

.course-resource__footer-subscribe {
  display: flex;
  align-items: center; }
  .course-resource__footer-subscribe:first-child:last-child {
    margin-left: auto; }
  .course-resource__footer-subscribe button {
    width: 28px;
    height: 28px;
    margin: 0;
    border-radius: 50%;
    color: #E9A065;
    padding: 0;
    margin-left: 0.5rem; }
    .course-resource__footer-subscribe button:hover svg,
    .course-resource__footer-subscribe button:focus svg {
      fill: #fff; }
  .course-resource__footer-subscribe a {
    color: #E9A065;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    text-decoration: none;
    font-weight: bold; }
  .course-resource__footer-subscribe svg {
    display: inline-block;
    width: 14px;
    height: 14px;
    position: relative;
    top: 1px;
    fill: #E9A065; }

.course__resources-block {
  position: relative;
  margin-bottom: 0;
  padding-bottom: 1px; }
  @media (min-width: 600px) {
    .course__resources-block ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; } }
  .course__resources-block li {
    margin-bottom: 24px;
    position: relative;
    width: 100%;
    max-width: 458px;
    margin-left: auto;
    margin-right: auto; }
    @media (min-width: 600px) {
      .course__resources-block li {
        width: calc(50% - 12px);
        margin-right: 12px;
        margin-left: 0; }
        .course__resources-block li:nth-child(2n) {
          margin-right: 0;
          margin-left: 12px; }
        .course__resources-block li:last-child {
          margin-bottom: 0; }
        .course__resources-block li:first-child:last-child {
          width: 100%; }
        .course__resources-block li:first-child:last-child {
          margin-left: auto;
          margin-right: auto; } }
    .course__resources-block li:last-child, .course__resources-block li:nth-child(odd):nth-last-child(2) {
      margin-bottom: 0; }
  .course__resources-block:after {
    content: '';
    position: absolute;
    background-color: #F5F5F5;
    left: -9999px;
    width: calc(100% + 19998px);
    bottom: 0;
    height: 4.5rem;
    z-index: -1; }
  .course__resources-block + .row__background-grey,
  .course__resources-block + .row__background-grey .share-block_is-done {
    margin-top: 0; }
  .course__resources-block .course__resource-number-bg {
    display: block; }
  .course__resources-block .resource-is-done .course-resource__card-image,
  .course__resources-block .resource-is-done .course-resource__text-snippet,
  .course__resources-block .resource-is-done .course-resource__parts-bookmarks {
    opacity: 0.6; }

.course__numbered-resource,
.course__resource-number-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 3.15rem;
  height: 3.25rem; }

.course__numbered-resource {
  color: #fff;
  font-family: "Cabin", sans-serif;
  z-index: 2; }
  .course__numbered-resource span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-55%, -55%); }

.course__resource-number-bg {
  display: none;
  z-index: 1;
  background-color: #94317A;
  border-radius: 6px 0 30px 0; }
  .resource-is-done .course__resource-number-bg {
    background-color: #5FAD56; }

.course-resource__done-date {
  display: none; }
  .course__resources-block .course-resource__done-date {
    display: block;
    margin: 0.25rem 1rem;
    position: relative;
    top: 0.5rem;
    color: #757575;
    font-size: 0.875rem; }
    .course__resources-block .course-resource__done-date span {
      text-transform: uppercase;
      color: #1f1f1f;
      padding-left: 24px; }

#course-resource__subscription-info {
  position: absolute;
  display: none;
  z-index: 2; }
  #course-resource__subscription-info.show {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  #course-resource__subscription-info .overlay-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    cursor: pointer; }

.course-resource__how-holder {
  background-color: #fff;
  position: relative;
  top: 5rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 24px;
  box-sizing: border-box;
  border-radius: 1rem;
  width: calc(100% - 40px);
  max-width: 780px;
  max-height: calc(100% - 12rem);
  overflow-y: scroll; }
  .course-resource__how-holder .how-it-works {
    margin: 0 !important; }
  .course-resource__how-holder .slider__buttons {
    margin-top: 6px;
    margin-bottom: 12px; }
    .course-resource__how-holder .slider__buttons button {
      padding: 6px;
      width: 6px;
      height: 6px;
      box-sizing: border-box; }
      .course-resource__how-holder .slider__buttons button:not(.active) {
        border-color: #E4E4E4; }
      .course-resource__how-holder .slider__buttons button:after {
        display: none; }
      .course-resource__how-holder .slider__buttons button span {
        position: absolute;
        left: -9999px; }
  .course-resource__how-holder h3,
  .course-resource__how-holder .how-works__flex {
    margin-top: 0; }
  .course-resource__how-holder li > p {
    text-align: center; }
  .course-resource__how-holder .close {
    margin-bottom: 0; }
    .course-resource__how-holder .close:hover, .course-resource__how-holder .close:focus {
      background-color: #fff;
      color: #E9A065; }

.how-it-works {
  max-width: 728px;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  @media (max-width: 767px) {
    .how-it-works {
      max-width: 450px; } }
  .how-it-works h2,
  .how-it-works h3 {
    font-size: 2.25rem;
    color: #1f1f1f;
    text-transform: none;
    letter-spacing: 0px;
    padding: 0; }
  .how-it-works h3 {
    margin-bottom: 0; }
  .how-it-works p {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto; }

@media (min-width: 768px) {
  .how-works__slide {
    padding: 1px; } }

.how-works__flex {
  margin-top: 36px; }
  @media (min-width: 768px) {
    .how-works__flex {
      display: flex;
      justify-content: space-between;
      align-items: center; } }

.how-works__text .button {
  margin: 1.25rem 0 1rem 0; }
  @media (min-width: 768px) {
    .how-works__text .button {
      max-width: 300px; } }

@media (min-width: 768px) {
  .how-works__text {
    order: 1;
    width: 50%;
    margin-right: 36px;
    text-align: left; } }

.how-works__image {
  margin: 0 auto;
  width: 300px;
  height: 300px;
  order: 2; }
  @media (max-width: 767px) {
    .how-works__image {
      width: 200px;
      height: 200px; } }

.medium__content > :first-child {
  margin-top: 0; }

.medium__content h1,
.medium__content h2,
.medium__content h3,
.medium__content p,
.medium__content ul,
.medium__content ol,
.medium__content blockquote {
  padding-left: 0;
  padding-right: 0;
  max-width: 780px;
  margin-left: auto;
  margin-right: auto; }

.medium__content img {
  display: block;
  width: auto !important;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }

.medium__content .medium-insert-embeds,
.medium__content .medium-insert-images,
.medium__content blockquote {
  margin-top: 36px;
  margin-bottom: 36px; }

@media (max-width: 599px) {
  .medium__content .medium-insert-images {
    margin-left: -1.5rem;
    width: calc(100% + 3rem); } }

.medium__content blockquote {
  display: block;
  max-width: 458px;
  border-top: 2px #E4E4E4 solid;
  border-bottom: 2px #E4E4E4 solid;
  padding: 12px 0;
  text-align: center; }
  .medium__content blockquote span {
    font-size: 1rem !important; }

.medium__content h3 {
  margin-top: 1em;
  margin-bottom: 1em; }
  @media (max-width: 767px) {
    .medium__content h3 {
      margin-top: 0.75em;
      margin-bottom: 0.75em; } }

.medium__content h1 {
  margin-top: 0.5em;
  margin-bottom: 0.5em; }

.medium__content h2 {
  text-transform: none;
  font-size: 2.5rem;
  color: #1f1f1f;
  margin-top: 0.75em;
  margin-bottom: 0.75em; }
  @media (max-width: 767px) {
    .medium__content h2 {
      font-size: 1.75rem; } }

.medium__content ul,
.medium__content ol {
  box-sizing: border-box;
  padding-left: 1.2rem; }

.medium__content a {
  color: #94317A;
  text-decoration: none; }
  .medium__content a:hover {
    text-decoration: underline; }

.share-block .button {
  max-width: 458px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px; }

.share-block .button-undone {
  border-color: #5FAD56; }
  .share-block .button-undone:hover, .share-block .button-undone:focus {
    background-color: #5FAD56; }

.share-block .button-done {
  border-color: #5FAD56;
  background-color: #5FAD56;
  color: #fff; }
  .share-block .button-done:hover, .share-block .button-done:focus {
    background-color: #F5F5F5;
    color: #1f1f1f; }

.share-block__share {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  align-items: center;
  margin-top: 24px;
  font-family: "Cabin", sans-serif; }
  .share-block__share a {
    background-color: #E9A065;
    display: block;
    width: 40px;
    height: 40px;
    margin-left: 16px;
    border-radius: 6px; }
  .share-block__share svg {
    position: relative;
    fill: #F5F5F5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.share-block_is-done {
  max-width: 780px;
  text-align: center;
  margin-left: auto;
  margin-right: auto; }

.theme_card {
  height: 100%; }
  .theme_card.course-resource__card {
    padding-bottom: 0; }
  .theme_card a {
    padding-bottom: 1rem;
    overflow: hidden; }
    .theme_card a:hover .course-resource__card-image, .theme_card a:focus .course-resource__card-image {
      transform: scale(1.15); }
  .theme_card .theme__image-holder {
    height: 150px;
    overflow: hidden;
    border-radius: 6px 6px 0 0; }
  .theme_card .course-resource__card-image {
    height: 150px;
    transition: transform 0.6s ease-in-out; }
  .theme_card .theme__description {
    position: relative;
    z-index: 1;
    margin: 1rem;
    margin-top: 35px;
    font-size: 0.875rem; }
  .theme_card .theme__card-top {
    position: relative; }
  .theme_card .theme__icon {
    position: absolute;
    bottom: -40px;
    left: 0;
    padding: 18px 14px;
    background-color: #fff;
    border-radius: 0 48px 0 0; }
    .theme_card .theme__icon img {
      width: 60px;
      height: 60px; }

.theme__empty {
  font-size: 0.935rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center; }

* {
  box-sizing: border-box; }

:last-child {
  margin-bottom: 0; }
