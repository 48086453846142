.container {
    width: 100%;
    max-width: $width-container;
    margin: auto;
    padding: 0 $width-gap;
    box-sizing: border-box;

    @media (max-width: 399px) {
        padding: 0 16px;
    }

    // Only the main container on a page
    main > &:first-child {
        min-height: 30rem;

        @media all and (max-width: 767px) {
            min-height: 18rem;
        }
    }
}
