.tabs__group {
    &.search__hide {
        opacity: 0;
    }

    h1 {
        @media (min-width: 768px) {
            margin-top: 0.65em;
            margin-right: 1rem;
        }
    }

    h2 {
        margin-bottom: 0;
    }
}

.tabs__content {
    display: block;

    &.tabs__hide {
        display: none;
    }
}

.tabs_buttons-holder {
    box-shadow: 0 9px 7px -4px rgba(0,0,0,0.05);
    margin-bottom: $width-gap*1.5;
}

.tabs__buttons {
    display: flex;

    button {
        border: 3px solid white;
        color: $color-lightest-readable-grey;
        margin: 0;
        font-size: 0.875rem;
        position: relative;
        box-sizing: border-box;

        &:after {
            content: '';
            position: absolute;
            bottom: -4px;
            height: 3px;
            background-color: $color-line;
            left: 0;
            width: 100%;
            display: none;
        }

        &[aria-expanded="true"] {
            color: $color-primary;

            &:after {
                display: block;
                background-color: $color-primary;
            }
        }

        &:hover,
        &:focus {
            background-color: #fff;
        }

        &:focus {
            outline: none;

            &:after {
                display: block;
            }

            &[aria-expanded="true"]:after {
                height: 4px;
            }
        }
    }
}

// Tabs which cover a full page of content ('my courses', 'bookmarks')
.tabs__full-page {
    .tabs_buttons-holder {
        box-shadow: none;

        @media (max-width: 767px) {
            margin-left: -0.75rem;
            width: calc(100% + 1.5rem);
        }
    }

    button {
        width: auto;

        &[aria-expanded="true"]:not(:focus) {
            &:after {
                display: none;
            }
        }
    }
}

.tabs__flex-header {
    margin-bottom: $width-gap * 1.5;

    @media (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
}

.tabs__courses {
    .course-resource__progress {
        display: block;
        width: 100%;
        order: 4;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }

    .course-resource__card-info {
        flex-wrap: wrap;
    }

    .course-resource__text-snippet {
        width: 50%;
    }
}

// Homepage specific
#tabs-home {
    margin-top: $width-gap;
}
