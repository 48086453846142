.demo-announcement {
    padding: 0.75rem 0;
    background: #000;
    color: #fff;
    text-align: center;
}

.page-header {
    padding-top: 60px;

    @media all and (max-width: 767px) {
        padding-top: 53px;
    }
}

.page-header__buttons {
    margin-left: auto;
}
