nav a {
    &.active,
    &:hover,
    &:focus {
        color: $color-primary;
        box-shadow: 0;
        outline: 0;

        svg {
            fill: $color-primary !important;
        }
    }
}

.nav {
    position: fixed;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    // Do not link to rem as rem changes significantly with user setting
    padding: 12px 0;
    background-color: $color-rol-magenta;
    font-family: $headers-font;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media all and (max-width: 767px) {
        padding: 0.5rem 0;
    }
}

#nav__after-header-links {
    display: none;

    @media all and (max-width: 767px) {
        display: block;
    }
}

.nav__desktop-top {
    display: flex;
    align-items: flex-end;

    @media all and (max-width: 767px) {
        display: none;
    }
}

.nav__mobile-footer {
    display: none;

    @media all and (max-width: 767px) {
        position: fixed;
        z-index: 10;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        background-color: #fff;
        border-top: $color-line solid 1px;
        // Must stay consistent across rem size changes
        height: $footer-height;

        ul {
            width: 100%;
        }

        a {
            display: block;
            padding: 0.5rem 0.25rem;
            color: $color-lightest-readable-grey;
            fill: $color-lightest-readable-grey;
            fill-opacity: 0.9;
            text-decoration: none;

            &:focus {
                position: relative;
                z-index: 1;
            }
        }

        .nav__logged-in li {
            width: 25%;
        }

        li {
            width: 50%;
            text-align: center;
        }

        p {
            margin-top: 0;
            line-height: 1;
            font-size: 0.8125rem;
        }
    }

    @media (max-width: 400px) {
        li {
            // allow uneven sizing to handle label lengths with largest text
            width: auto !important;
            flex-grow: 1;
        }
    }
}

.nav__mobile-footer,
.nav__primary {
    ul {
        @extend %reset-list;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
    }

    svg {
        @extend %icon-svg;
    }
}

.nav__primary {
    flex-shrink: 0;
    position: relative;
    top: 2px;

    a {
        display: block;
        // Do not link to rem as rem changes significantly with user setting
        padding: 5px 12px;
        text-decoration: none;
        color: #fff;

        @media all and (max-width: 767px) {
            padding: 6px;
            font-size: 0.8125rem;
        }
        svg {
            fill: #ffffff;
        }
    }

    @media all and (max-width: 767px) {
        text-transform: uppercase;

        svg {
            max-width: 18px;
        }
    }
}

// Branch, logos
.nav__brand,
.footer__brand {
    flex-shrink: 1;
    display: inline-block;
    position: relative;
    top: -1px;

    @media (min-width: 400px) {
        top: -2px;
    }
}

.footer__brand {
    margin-bottom: $width-gap;
}

.nav__brand {
    width: 8.8125rem;
    padding-right: 1rem;
    display: flex;
    align-items: flex-end;
}
