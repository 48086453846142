// All hero sections
.hero,
.hero__course-resource {
    // Do not use rem here as rem value may be changed
    position: relative;
    padding: 40px 0 45px 0;

    @media (min-width: 768px) {
        padding: 80px 0 85px 0;
    }

    @media (min-width: 1024px) {
        padding: 100px 0 100px 0;
    }

    h1,
    p,
    .h2 {
        color: #fff;
        max-width: 26rem;
    }

    .h2 {
        padding: 0;
        font-size: 0.875rem;
    }

    h1 {
        margin: 0;
    }

    p {
        font-family: $headers-font;
        font-weight: 400;
    }

    span {
        display: block;
    }
}

.hero__background,
.hero__course-resource-background,
.hero__course-resource-background-mobile,
.hero__course-resource-background-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: $color-text;
    background-size: cover;
    background-position: center;
}

// Homepage hero only
.hero__background {
    background-image: url(../img/hero-768.jpg);

    @media (min-width: 768px) {
        background-image: url(../img/logged-in-hero-1920.jpg);
    }

    .hero_bg-stretch & {
        bottom: -6rem;
    }
}

// Course / Resource hero only
.hero__course-resource {
    h1,
    p {
        max-width: $max-width-medium-pages;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }

    .course-resource__divider:after {
        background-color: #fff;
    }

    svg {
        @extend %icon-svg;
        fill: #fff;
    }
}

.course-resource-hero__info {
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-size: 0.875rem;

    span {
        line-height: 1.35;
    }
}

.hero__course-resource-background-overlay {
    background-color: rgba(0, 0, 0, 0.6);
}

// Load only the more appropriate size
.hero__course-resource-background {
    @media (max-width: 767px) {
        display: none;
        background-image: none !important;
    }
}

.hero__course-resource-background-mobile {
    @media (min-width: 768px) {
        display: none;
        background-image: none !important;
    }
}
