footer {
    margin-top: $width-gap * 2;
    font-family: $headers-font;
    background: $color-secondary;
    color: #fff;
    padding: 1.25*$width-gap 0 0.2*$width-gap 0;
}

.footer__main {
    // Space for fixed bar
    @media all and (max-width: 767px) {
        padding-bottom: 3.75rem;
    }

    // Or two fixed bars on course/resource detail pages
    .course-resource__footer & {
        padding-bottom: 2rem;

        @media all and (max-width: 767px) {
            padding-bottom: 5.75rem;
        }
    }

    .row {
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0;

        & + .row {
            margin-top: $width-gap * 2;

            @media all and (max-width: 767px) {
                margin-top: $width-gap * 1.25;
            }
        }
    }

    ul {
        @extend %reset-list;

        @media all and (max-width: 767px) {
            margin-top: $width-gap;
        }
    }

    a {
        display: block;
        padding: $width-gap * 0.35;
        text-decoration: none;

        @media (max-width: 767px) {
            padding: $width-gap * 0.25;
        }
    }

    p,
    p:last-child {
        margin: 0 0 0.65 * $width-gap 0;
    }

}

.footer__logos {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    max-width: 100%;
}

.footer__useful-links ul {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: $width-gap;

    @media (max-width: 499px) {
        // IE11
        width: 280px;

        @supports (flex-wrap: wrap) {
            width: 100%;
        }
    }
}

.footer__logos {

    img:first-child {
        width: 150px;
        margin-right: $width-gap;
    }

    img:last-child {
        width: 130px;
    }

    @media all and (max-width: 399px) {
        img {
            max-width: 120px;
        }
    }
}

.footer__copyright,
.footer__credit {
    font-size: 0.75rem;
}
