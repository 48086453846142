.how-it-works {
    max-width: 728px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @media (max-width: 767px) {
        max-width: 450px;
    }

    h2,
    h3 {
        font-size: 2.25rem;
        color: $color-text;
        text-transform: none;
        letter-spacing: 0px;
        padding: 0;
    }

    h3 {
        margin-bottom: 0;
    }

    p {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
}

.how-works__slide {
    @media (min-width: 768px) {
        // (IE11 fix)
        padding: 1px;
    }
}

.how-works__flex {
    margin-top: $width-gap * 1.5;

    @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.how-works__text {

    .button {
        margin: 1.25rem 0 1rem 0;

        @media (min-width: 768px) {
            max-width: 300px;
        }
    }

    @media (min-width: 768px) {
        order: 1;
        width: 50%;
        margin-right: $width-gap * 1.5;
        text-align: left;
    }
}

.how-works__image {
    margin: 0 auto;
    width: 300px;
    height: 300px;
    order: 2;

    @media (max-width: 767px) {
        width: 200px;
        height: 200px;
    }
}
