.course-resource__detail-bookmark {
    font-size: 0.875rem;
    position: relative;
    margin-right: 0.5rem;

    svg {
        @extend %icon-svg;
        position: relative;
        top: 1px;
        fill: $color-lightest-readable-grey;
    }

    &:not(.is-bookmarked) svg {
        fill: transparent;
        stroke-width: 8px;
        stroke: #999;
    }

    a {
        display: block;
        padding: 4px 8px 4px 0;
    }
}

.course-resource__detail-progress {
    .course-resource__progress {
        display: block;
        padding: 0;
        align-items: center;
        max-width: $max-width-medium-pages;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2.5rem;
        margin-bottom: -1rem;

        h2 {
            margin-top: 0;
            margin-bottom: 0.5rem;
            max-width: $max-width-medium-pages;
        }

        h2,
        span {
            font-size: 0.8765rem;
        }

        @media (max-width: 599px) {
            order: 3;
            margin-right: 0;
            width: 100%;
            margin-top: 1rem;
        }
    }
}

.course-resource__done-indicator {
    position: relative;
    opacity: 0.35;
    letter-spacing: 1.2px;
    @extend %done-tick;

    span {
        margin-left: 24px;
        text-transform: uppercase;
    }

    a {
        text-decoration: none;
    }

    &:before {
        border-color: $color-lightest-readable-grey;
    }
}

// ----------------------------------------------------------
// Resources only - top done message
// ----------------------------------------------------------
.course-resource__detail-top-done-message {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: $color-success;
    color: #fff;
    padding: 0.35rem 0 0.2rem 0;
    font-size: 0.875rem;

    .course-resource__done-indicator {
        color: #fff;
        opacity: 1;

        &:before {
            border-color: #fff;
        }
    }

    .container {
        display: flex;
        justify-content: space-between;
    }
}

// ----------------------------------------------------------
// Extra fixed footer on course / resource detail
// ----------------------------------------------------------
.course-resource__detail-footer {
    position: fixed;
    z-index: 3;
    left: 0;
    bottom: 0;
    right: 0;
    height: 40px;
    background-color: rgba(245, 245, 245, 0.9);
    border-bottom: 1px $color-line solid;
    border-top: 1px $color-line solid;
    font-size: 0.875rem;
    padding: 2px 0;
    font-family: $headers-font;

    @media (max-width: 767px) {
        bottom: $footer-height;
    }

    &.space-between .container {
        justify-content: space-between;
    }

    &.is_done .course-resource__done-indicator {
        opacity: 1;
        color: $color-success;

        &:before {
            border-color: $color-success;
        }
    }

    .container {
        display: flex;
        align-items: center;
    }

    .footer-divider {
        display: block;
        width: 1px;
        height: 34px;
        margin-left: 0.5rem;
        background-color: $color-line;
    }
}

.course-resource__footer-subscribe {
    display: flex;
    align-items: center;

    &:first-child:last-child {
        margin-left: auto;
    }

    button {
        width: 28px;
        height: 28px;
        margin: 0;
        border-radius: 50%;
        color: $color-primary;
        padding: 0;
        margin-left: 0.5rem;

        &:hover svg,
        &:focus svg {
            fill: #fff;
        }
    }

    a {
        color: $color-primary;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        text-decoration: none;
        font-weight: bold;
    }

    svg {
        display: inline-block;
        width: 14px;
        height: 14px;
        position: relative;
        top: 1px;
        fill: $color-primary;
    }
}

// ----------------------------------------------------------
// Block for resources in course detail view (2 across)
// ----------------------------------------------------------
.course__resources-block {
    position: relative;
    margin-bottom: 0;
    padding-bottom: 1px;

    ul {
        @extend %reset-list;

        @media (min-width: 600px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    li {
        margin-bottom: $width-gap;
        position: relative;
        width: 100%;
        max-width: $max-width-mobile;
        margin-left: auto;
        margin-right: auto;

        @media (min-width: 600px) {
            width: calc(50% - 12px);
            margin-right: 12px;
            margin-left: 0;

            &:nth-child(2n) {
                margin-right: 0;
                margin-left: 12px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:first-child:last-child {
                width: 100%;
            }

            &:first-child:last-child {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &:last-child,
        &:nth-child(odd):nth-last-child(2) {
            margin-bottom: 0;
        }
    }

    &:after {
        content: '';
        position: absolute;
        background-color: $color-row-bg;
        left: -9999px;
        width: calc(100% + 19998px);
        bottom: 0;
        height: 4.5rem;
        z-index: -1;
    }

    & + .row__background-grey,
    & + .row__background-grey .share-block_is-done {
        margin-top: 0;
    }

    .course__resource-number-bg {
        display: block;
    }

    .resource-is-done {
        .course-resource__card-image,
        .course-resource__text-snippet,
        .course-resource__parts-bookmarks {
            opacity: 0.6;
        }
    }
}

.course__numbered-resource,
.course__resource-number-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 3.15rem;
    height: 3.25rem;
}

.course__numbered-resource {
    color: #fff;
    font-family: $headers-font;
    z-index: 2;

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-55%, -55%);
    }
}

.course__resource-number-bg {
    display: none;
    z-index: 1;
    background-color: $color-rol-magenta;
    border-radius: 6px 0 $width-gap * 1.25 0;

    .resource-is-done & {
        background-color: $color-success;
    }
}

.course-resource__done-date {
    display: none;

    .course__resources-block & {
        display: block;
        margin: 0.25rem 1rem;
        position: relative;
        top: 0.5rem;
        color: $color-lightest-readable-grey;
        font-size: 0.875rem;
        @extend %done-tick;

        span {
            text-transform: uppercase;
            color: $color-text;
            padding-left: 24px;
        }
    }
}

// ----------------------------------------------------------
// Popup subscription info
// ----------------------------------------------------------
#course-resource__subscription-info {
    position: absolute;
    display: none;
    z-index: 2;

    &.show {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .overlay-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
        cursor: pointer;
    }
}

.course-resource__how-holder {
    background-color: #fff;
    position: relative;
    top: 5rem;
    left: 50%;
    transform: translateX(-50%);
    padding: $width-gap * 0.5 $width-gap;
    box-sizing: border-box;
    border-radius: 1rem;
    width: calc(100% - 40px);
    max-width: $max-width-medium-pages;
    max-height: calc(100% - 12rem);
    overflow-y: scroll;

    .how-it-works {
        margin: 0 !important;
    }

    .slider__buttons {
        margin-top: $width-gap * 0.25;
        margin-bottom: $width-gap * 0.5;

        button {
            padding: 6px;
            width: 6px;
            height: 6px;
            box-sizing: border-box;

            &:not(.active) {
                border-color: $color-line;
            }

            &:after {
                display: none;
            }

            span {
                position: absolute;
                left: -9999px;
            }
        }
    }

    h3,
    .how-works__flex {
        margin-top: 0;
    }

    li > p {
        text-align: center;
    }

    .close {
        margin-bottom: 0;

        &:hover,
        &:focus {
            background-color: #fff;
            color: $color-primary;
        }
    }
}
