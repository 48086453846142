.slider__multi-across {
    width: calc(100% + 0.5rem);
    position: relative;
    top: -0.25rem;
    left: -0.25rem;

    ul {
        @media (max-width: 767px) {
            overflow: visible !important;
        }
    }

    li {
        padding: $width-gap * 0.25;
        box-sizing: border-box;
    }

    @media (max-width: 499px) {
        max-width: $max-width-mobile;
    }
}

// 'dots' buttons
.slider__buttons {
    display: flex;
    justify-content: center;
    margin-bottom: $width-gap;

    button {
        margin: 0 1rem;
        width: 3rem;
        border-radius: 50%;
        position: relative;
        font-family: $headers-font;
        transform: scale(0.9);

        &.active {
            transform: scale(1);
            background-color: $color-primary;
            color: #fff;
        }

        &:not(:last-child):after {
            content: '';
            position: absolute;
            left: 130%;
            top: calc(50% - 1px);
            width: 1rem;
            height: 2px;
            background-color: $color-line;
        }

        &:focus {
            @extend %replicate-webkit-outline;
        }
    }
}

// Previous & next
.slider__next,
.slider__prev {
    &, &:focus {
        top: 3px;
        left: 3px;
        bottom: 4px;
        width: 35px;
        margin: 0 !important;
        z-index: 1;
        background: rgba(255,255,255,0.9);
        color: $color-primary;
        border: none;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 15px;
            height: 15px;
            background-color: transparent;
            border-top: $color-primary 3px solid;
            border-left: $color-primary 3px solid;
            transform: translate(-25%, -50%) rotate(-45deg);
        }
    }

    &.hide-link {
        display: none;
    }
}

.slider__next {
    &, &:focus {
        left: auto;
        right: 3px;

        &:after {
            transform: translate(-50%, -50%) rotate(135deg);
        }
    }
}

.slider {

    li {
        position: relative;
        transition: transform 0.3s ease-in-out;

        &[aria-hidden="true"] { // (offscreen)
            transform: scaleY(0.96);
        }
    }

    ul {
        @extend %reset-list;

        &:after {
            content: '';
            display: block;
            clear: both;
        }

        // Special case for different desktop view
        &.non-slide-desktop {
            @media (min-width: 768px) {
                display: flex;
                flex-wrap: wrap;
                margin-top: -0.125rem;

                li {
                    min-width: 25%;
                    max-width: 50%;
                    width: 33.33%;
                    flex-grow: 1;
                    flex-shrink: 1;

                    &:nth-last-child(-n+2):not(.no-remainder),
                    &:nth-last-child(-n+4):not(.no-remainder):not(.remainder-two), {
                        width: 50%;
                    }
                }

                .slider__next,
                .slider__prev {
                    display: none;
                }
            }
        }
    }
}
