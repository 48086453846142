.button,
.btn,
button,
input[type="submit"] {
    display: block;
    width: 100%;
    padding: $width-gap / 2;
    text-decoration: $style-button;
    border: $width-line solid $color-primary;
    background-color: transparent;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    cursor: pointer;
    color: $color-text;
    text-align: center;
    font-family: $headers-font;
    max-width: $max-width-mobile;

    &:first-child,
    &:last-child {
        margin: $width-gap auto;

        @media (max-width: 767px) {
            margin: $width-gap*1.5 auto;
        }
    }

    &:hover,
    &:focus {
        background: $color-primary;
        color: #fff;
    }

    &.secondary {
        background: $color-primary;
        color: #fff;
        border: none;

        &:hover,
        &:focus {
            background: lighten($color-primary, 3%);
        }
    }

    &.tertiary {
        text-transform: none;
        border: none;
        font-size: 0.935rem;
        color: $color-primary;
        background: none;
    }

    &.btn-small {
        padding: $width-gap / 4;
        font-size: 0.875rem;
        max-width: 15rem;
    }
}

a.color-link {
    text-transform: uppercase;
    color: $color-primary;
    text-decoration: none;
}
