.theme__slider-link {
    a {
        @extend %rol-shadow;
        background-color: #fff;
        display: block;
        padding: 16px;
        position: relative;
        border-radius: 6px;
        text-align: center;

        &:focus {
            position: relative;
            z-index: 1;
        }
    }

    img {
        width: 100%;
        max-width: 80px;
        max-height: 80px;
    }
}
