.medium__content {
    > :first-child {
        margin-top: 0;
    }

    h1,
    h2,
    h3,
    p,
    ul,
    ol,
    blockquote {
        padding-left: 0;
        padding-right: 0;
        max-width: $max-width-medium-pages;
        margin-left: auto;
        margin-right: auto;
    }

    img {
        display: block;
        // Don't stretch images
        width: auto !important;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .medium-insert-embeds,
    .medium-insert-images,
    blockquote {
        margin-top: $width-gap * 1.5;
        margin-bottom: $width-gap * 1.5;
    }

    .medium-insert-images {
        @media (max-width: 599px) {
            margin-left: -1.5rem;
            width: calc(100% + 3rem;)
        }
    }

    blockquote {
        display: block;
        max-width: $max-width-mobile;
        border-top: 2px $color-line solid;
        border-bottom: 2px $color-line solid;
        padding: $width-gap*0.5 0;
        text-align: center;

        span {
            // prevent this from being set to 14px regardless of rem size
            font-size: 1rem !important;
        }
    }

    h3 {
        margin-top: 1em;
        margin-bottom: 1em;

        @media (max-width: 767px) {
            margin-top: 0.75em;
            margin-bottom: 0.75em;
        }
    }

    h1 {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

    h2 {
        text-transform: none;
        font-size: 2.5rem;
        color: $color-text;
        margin-top: 0.75em;
        margin-bottom: 0.75em;

        @media (max-width: 767px) {
            font-size: 1.75rem;
        }
    }

    ul,
    ol {
        box-sizing: border-box;
        padding-left: 1.2rem;
    }

    a {
        color: $color-rol-magenta;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
