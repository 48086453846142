// theme cards/snippets inherit many styles from course|resource cards/snippets
.theme_card {
    height: 100%;

    &.course-resource__card {
        padding-bottom: 0;
    }

    a {
        padding-bottom: 1rem;
        overflow: hidden;

        &:hover,
        &:focus {
            .course-resource__card-image {
                transform: scale(1.15);
            }
        }
    }

    .theme__image-holder {
        height: 150px;
        overflow: hidden;
        border-radius: 6px 6px 0 0;
    }

    .course-resource__card-image {
        height: 150px;
        transition: transform 0.6s ease-in-out;
    }

    .theme__description {
        position: relative;
        z-index: 1;
        margin: 1rem;
        margin-top: 35px;
        font-size: 0.875rem;
    }

    .theme__card-top {
        position: relative;
    }

    .theme__icon {
        position: absolute;
        bottom: -40px;
        left: 0;
        padding: 18px 14px;
        background-color: #fff;
        border-radius: 0 48px 0 0;

        img {
            width: 60px;
            height: 60px;
        }
    }
}

.theme__empty {
    font-size: 0.935rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
}
