@import '_extra-links';
@import '_messages';
@import '_filter';
@import '_search';

form {
    // (Button styling in scss/elements/_buttons.scss)
    margin: 2rem 0 1rem 0;

    > .control-group,
    .form__margin-top,
    .control-group__full-width {
        margin-bottom: $width-gap;
        color: $color-text;
    }

    // elements which should be full with on a 2-column form
    // may be groups of elements
    .control-group__full-width {
        width: 100%;

        .control-group + .control-group {
            margin-top: $width-gap * 0.5;
        }
    }

    .form__desktop-cols & {
        @media all and (min-width: 768px) {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-between;
            align-items: flex-start;

            > .control-group,
            > .buttonHolder,
            > .form__rol-placeholder {
                width: calc(50% - 1.5rem);
            }
        }
    }

    .form__subsect {
        font-weight: bold;
        margin-top: $width-gap * 0.75;

        @media (max-width: 767px) {
            margin-top: $width-gap * 1.5;
        }
    }

    // Target labels used by text fields, not radios/checkboxes
    .control-group > label {
        display: block;
        font-family: 'Cabin', sans-serif;
        color: $color-lightest-readable-grey;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        font-size: 0.935rem;
        margin-bottom: 0.5rem;

        @media all and (max-width: 767px) {
            font-size: 0.875rem;
        }
    }

    //----------------------------- Custom checkboxes, radiobuttons

    label.checkbox,
    label.radio {
        padding-left: 2rem;
        position: relative;
        box-sizing: border-box;
        display: block;
    }

    .custom-checkbox,
    .custom-radio {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 1.35rem;
        height: 1.35rem;
        border: $width-line solid $color-line;
        margin-right: 0.25rem;

        &:before {
            content: '';
            position: absolute;
            display: none;
        }
    }

    .custom-checkbox:before {
        top: 2px;
        height: 0.5rem;
        width: 0.2rem;
        border: 2px solid $color-text;
        border-left: none;
        border-top: none;
        transform: translateX(0.35rem) rotate(45deg);
    }

    .radio {
        display: block;

        // These should be in fixed px, and not depend on current rem value
        &[for='id_id_font_size_0_1'] {
            margin-bottom: 0.5rem;
            font-size: 16px;
        }

        &[for='id_id_font_size_0_2'] {
            font-size: 18px;
            margin-bottom: 0.5rem;
        }

        &[for='id_id_font_size_0_3'] {
            font-size: 20px;
        }
    }

    .custom-radio {
        border-radius: 50%;
        margin-right: 0.5rem;

        &:before {
            top: calc(50% - 0.25rem);
            left: calc(50% - 0.25rem);
            width: 0.5rem;
            height: 0.5rem;
            background-color: $color-primary;
            border-radius: 50%;
        }
    }

    input[type='checkbox'],
    input[type='radio'] {
        position: absolute;
        left: -999999px;
    }

    input[type='checkbox']:checked + .custom-checkbox:before,
    input[type='radio']:checked + .custom-radio:before {
        display: block;
    }

    input[type='checkbox']:focus + .custom-checkbox,
    input[type='radio']:focus + .custom-radio {
        border-color: $color-primary;
    }

    //----------------------------- Inputs

    input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='file']),
    textarea,
    select {
        display: block;
        appearance: none;
        width: 100%;
        padding: $width-gap / 2;
        border: $width-line solid $color-line;
        border-radius: $width-radius;
        background: #fff;
        vertical-align: top;
        font-size: inherit;
        font-family: inherit;
        -webkit-font-smoothing: antialiased;
        line-height: normal;
        color: $color-text;
    }

    input::placeholder {
        color: lighter($color-text, 20%);
    }

    input::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: lighter($color-text, 35%);
    }

    //----------------------------- Profile image

    .profile-image__holder {
        .control-group {
            position: absolute;
            left: -999999px;
        }

        button {
            margin: 0.5rem 0;
        }
    }

    #profile-img__preview-holder {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: $color-line;
    }

    .profile-image__custom {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        @media (max-width: 399px) {
            justify-content: center;
        }
    }

    .profile-image__img {
        position: relative;
        width: 140px;
        height: 140px;
        margin-right: 16px;
        background-color: $color-line;
        background-image: url(../img/profile-default.png);
        background-size: contain;

        // these values should not increase with rem
        // as space will become more limited as font size increases
        img {
            max-width: 100%;
            max-height: 100%;
        }

        @media (max-width: 399px) {
            margin-bottom: 1rem;
        }
    }

    #profile-pic__help,
    #profile-img__preview-holder {
        display: none;

        &.show {
            width: 100%;
            display: block;
        }
    }

    //----------------------------- Help / errors / reset password links

    a:first-child {
        display: block;
        color: $color-text;
        font-size: 0.935rem;

        &:not(.button) {
            margin-top: $width-gap * 0.5;
        }
    }

    .alert-block {
        ul {
            @extend %reset-list;
            margin-bottom: $width-gap;
            border-left: $color-primary 5px solid;
            padding-left: 0.5rem;
        }
    }

    .help-inline,
    .help-block {
        display: block;
        font-size: 0.875rem;
    }

    .errorlist {
        @extend %reset-list;
        margin-bottom: $width-gap;
        color: $color-primary;
        font-weight: bold;
    }
}

.half-width form {
    max-width: 494px !important;

    .submit.btn {
        margin-left: 0;
        margin-right: 0;
        max-width: 494px !important;
    }
}
