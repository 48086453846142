.filter__holder {
    position: relative;
    margin-left: auto;

    &:last-child {
        margin-bottom: 1rem;
    }

    button {
        margin: 0 3px 0 0;
    }
}

.filter__form {
    position: absolute;
    right: 0;
    bottom: -3.5rem;
    z-index: 2;
    width: 290px;

    p,
    form {
        margin: 0;
    }

    label {
        display: none;
    }

    select {
        border-color: $color-primary;
    }
}

.filter__toggle-show {
    display: flex;
    align-items: center;
    width: auto;
    border: none;
    color: $color-lightest-readable-grey;
    letter-spacing: 1.2px;
    font-family: $headers-font;
    font-weight: 600;
    padding: 0.25rem;

    &:hover,
    &:focus {
        background-color: #fff;
        color: $color-lightest-readable-grey;
    }

    &[aria-expanded='false'] + .filter__form {
        display: none;
    }
}

.filter__ico-holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 1.5rem;
    height: 20px;
    margin-left: $width-gap * 0.5;

    &:before,
    &:after {
        content: '';
    }

    &:before,
    &:after,
    span {
        display: block;
        width: 100%;
        height: 4px;
        background-color: $color-primary;
    }

    span {
        transform: scaleX(0.65);
    }

    &:after {
        transform: scaleX(0.2);
    }
}
