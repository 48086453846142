// Base sizes
$base-font-size: 1rem !default;
$base-line-height: 1.5 !default;

$base-font: 'Open Sans', sans-serif;
$headers-font: 'Cabin', sans-serif;

// Colors
$color-text: #1f1f1f !default;
$color-background: #1a191b !default;

$color-inverted-background: #000000 !default;
$color-inverted-text: #ffffff !default;

$color-primary: #E9A065 !default;
$color-secondary: #A688B7 !default;
$color-accent: #221F72 !default;
$color-line: #E4E4E4 !default;
$color-rol-magenta: #94317A;
$color-lightest-readable-grey: #757575;
$color-row-bg: #F5F5F5;
$color-success: #5FAD56;

// Size
$width-gap: 24px;
$width-line: 3px;
$width-container: 988px !default;
$width-column-gap: 1.5rem;
$width-radius: 0.125rem;
$max-width-mobile: 458px;
$max-width-medium-pages: 780px;
$height-logo-ratio: 21.89%;
$footer-height: 70px;

// Style
$style-button: none;

// Icon
$icon-width: 1.5em;
$icon-height: $icon-width;


// Headlines

///     FONT SIZE  |  LINE H  |  MARGIN T  |  MARGIN B
$headline-scale: (
    h1: (4rem,        1.275,     1.2em,         1em),
    h2: (1rem,        1.275,     1em,         1em),
    h3: (1.5rem,      1.275,     1.33em,      0.66em),
    h4: (1.25rem,     1.2,       1.2em,       0.8em),
    h5: (1.1rem,      1.1,       1.125em,     0.9em),
    h6: (1rem,        1,         1.5em,       0.5em)
) !default;
