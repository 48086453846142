.sr__text,
.sr__input,
.sr__label {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

// Should be absolutely positioned as should not take up space,
// should be visible on focus
.focus-visible {
    position: absolute;

    &:not(:focus) {
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
    }
}

.sr__text,
.sr__input,
.sr__label {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

:focus {
    outline: none;
}

body.user-tabbing {
    a,
    button,
    .btn,
    .button,
    input {
        &:focus {
            box-shadow: 0 0 0.125rem 0.125rem $color-primary;
        }
    }

    .image-upload__container {
        &:focus,
        &:focus-within {
            box-shadow: 0 0 0.125rem 0.125rem $color-primary;
        }
    }

    form {
        input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='button']),
        textarea,
        select,
        button {
            &:focus-within {
                box-shadow: none;
                border-color: $color-primary;
            }
        }

        label.radio {
            &:focus-within {
                box-shadow: 0 0 0.125rem 0.125rem $color-primary;
            }
        }

        .checkbox:focus .custom-checkbox {
            box-shadow: 0 0 0.125rem 0.125rem $color-primary;
        }

        &:focus {
            box-shadow: 0 0 0.125rem 0.125rem $color-primary;
        }
    }
}
