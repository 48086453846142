// User changeable base font sizes
html.medium {
    font-size: 18px;
}

html.large {
    font-size: 20px;
}

body {
    font-family: $base-font, sans-serif;
    line-height: 1.5;
    color: $color-text;
}

//// HEADLINES

// loop through heading size variable and assign sizes
@each $heading, $font-properties in $headline-scale {
    #{$heading}, .#{$heading} {
        font-size: nth($font-properties, 1);
        line-height: nth($font-properties, 2);
        margin-top: nth($font-properties, 3) * 1;
        margin-bottom: nth($font-properties, 4) * 1;
    }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-weight: 600;
    font-family: 'Cabin', sans-serif;

    &.inverted {
        color: $color-inverted-text;
    }

    &.text-color {
        color: $color-text;
    }
}

.center {
    text-align: center;
}

h1, .h1 {

    @media all and (max-width: 767px) {
        font-size: 2.25rem;
        margin-top: 1.5em;
        margin-bottom: 0.5em;
    }
}

h2, .h2 {
    color: $color-lightest-readable-grey;
    text-transform: uppercase;
    letter-spacing: 1.2px;

    @media (max-width: 767px) {
        max-width: $max-width-mobile;
        margin-left: auto;
        margin-right: auto;
    }
}

.line-break__mobile {
    @media all and (max-width: 767px) {
        display: block;
    }
}

//// COMMON

a {
    color: inherit;
}

hr {
    border: none;
    height: $width-gap * 0.125;
    background: $color-text;
}
