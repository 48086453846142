#search__input {
    padding: 1px;
    background-color: $color-rol-magenta;
    color: #fff;

    h1 {
        margin-bottom: $width-gap;
    }

    input {
        margin: 0 1rem $width-gap*1.5 1rem;
        padding: 0.5rem 0;
        background-color: transparent;
        border: none;
        border-bottom: 3px solid #fff;
        color: #fff;
        width: 100%;
        max-width: 600px;

        &:focus {
            outline: none;
        }

        @media (max-width: 767px) {
            margin: 0 0 $width-gap 0;
        }
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #fff;
    }
}

#tabs-group-search {
    margin-top: $width-gap * 0.5;
}

#search__filters {
    transition: opacity 0.3s ease-in-out;

    &.grey-out {
        opacity: 0.5;
    }

    &.search__hide {
        opacity: 0;
    }

    .h2 {
        margin-top: 0;
        margin-bottom: 0.75rem;

        @media (max-width: 767px) {
            max-width: none;
        }
    }
}

.search__results {
    max-width: $max-width-mobile;
    margin-top: $width-gap;
    margin-left: auto;
    margin-right: auto;
    min-height: 300px;
    transition: opacity 0.3s ease-in-out;

    @media (min-width: 768px) {
        max-width: 600px;
    }

    &.fade-out {
        opacity: 0;
    }

    &.hide {
        display: none;
    }

    .course-resource__card {
        max-width: none;
        margin-bottom: 1rem;
    }

    a {
        display: block;
    }
}

.search__filter-holder {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;
    top: -0.5rem;
    width: calc(100% + 0.5rem);
    margin-left: -0.25rem;
    min-height: 2.5rem;
}

.search__filter-button {
    width: auto;
    margin: 0.25rem !important;
    border-width: 2px;
    border-color: $color-row-bg;
    font-size: 0.875rem;

    &.active {
        border-color: $color-success;
    }

    &:hover,
    &:focus {
        background-color: $color-row-bg;
        color: $color-text;
    }
}

.search__pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    button {
        width: calc(50% - 0.75rem);
        padding-left: $width-gap;
        padding-right: $width-gap;
        margin: 0.5rem 0.75rem 0.5rem 0;

        &:last-child {
            margin: 0.5rem 0 0.5rem 0.75rem;
        }

        &:not(.show) {
            display: none;
        }
    }
}
