// 1.2375 * 3 lines3 (+.5 margin between h4 & p)
$text_snippet_line_height: 1.35rem;
$text_snippet_height: $text_snippet_line_height * 3;

// Block for 3 snippets across (desktop)
.snippet-list-block {
    @extend %reset-list;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: stretch;
    top: -0.25rem;
    left: -0.25rem;
    width: calc(100% + 0.5rem);

    @media (max-width: 767px) {
        display: block;
    }

    li {
        padding: $width-gap * 0.25;
        box-sizing: border-box;

        &:last-child .course-resource__card {
            margin-bottom: 0;
        }

        @media (min-width: 768px) {
            width: 33.33%;
            flex-shrink: 0;
            padding-bottom: 0;

            &:nth-child(n + 4) {
                margin-top: $width-gap * 0.5;
            }

            &:first-child:last-child,
            &:first-child:nth-last-child(2),
            &:last-child:nth-child(2) {
                width: 50%;
            }
        }
    }
}

// Courses / resources 'snippet' cards
.course-resource__card {
    max-width: $max-width-mobile;
    margin: 0 1rem 0 0;
    border-bottom: 1px $color-line solid;
    padding-bottom: 0.75rem;
    background-color: #fff;
    height: 100%;

    @supports (flex-wrap: wrap) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    @media (max-width: 767px) {
        height: auto;

        &:last-child {
            margin: 0 auto $width-gap * 0.5 auto;
        }
    }

    // Match p & h4 total line heights
    h4 {
        overflow: hidden;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1.125rem;
        line-height: $text_snippet_line_height;
        max-height: $text_snippet_line_height * 2.95;
        flex-shrink: 0;
    }

    span {
        font-size: 0.875rem;
    }

    a {
        text-decoration: none;
        height: 100%;
        border-radius: 6px;
    }

    .snippets__featured & {
        @extend %rol-shadow;
        margin: 0 auto $width-gap * 0.5 auto;
        border-radius: 6px;
        padding-bottom: 1rem;
    }
}

.course-resource__card-info {
    display: flex;
    align-items: flex-start;

    .snippets__featured & {
        display: block;
    }
}

// Progress
.course-resource__progress {
    display: none;
    padding: 1rem;
    font-family: $headers-font;

    .progress-holder {
        width: 100%;
        height: 0.25rem;
        background-color: $color-line;
        position: relative;
        border-radius: 0.25rem;
        overflow: hidden;
    }

    .progress-indicator {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform-origin: left top;
        background-color: $color-success;
    }

    .progress-labels {
        display: flex;
        justify-content: space-between;
        color: $color-lightest-readable-grey;
        margin-top: 0.25rem;

        :first-child {
            color: $color-success;
        }
    }

    .snippets__featured.snippets__subscribed & {
        display: block;
    }
}

// Show ellipses on text section if it overflows
.course-resource__text-overflow,
.course-resource__text-overflow p {
    position: relative;

    &:after {
        content: '...';
        position: absolute;
        bottom: 1px;
        right: 0;
        height: $text_snippet_line_height;
        width: 0.75rem;
        background-color: #fff;
        line-height: $text_snippet_line_height;
    }
}

.course-resource__text-overflow {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: #fff;

    p {
        flex-grow: 1;
        margin-top: 0;
        padding-right: 0.75rem;
        line-height: $text_snippet_line_height;
        font-size: 0.875rem;

        &:after {
            content: '';
            z-index: 1;
        }
    }
}

.course-resource__text-snippet {
    display: flex;
    flex-direction: column;
    height: $text_snippet_height;
    overflow: hidden;
    flex-grow: 1;

    .snippets__featured & {
        margin: 1.25rem 1rem 0 1rem;
    }

    .snippets__subscribed & {
        margin-top: 0;
    }
}

// Snippet card image
.course-resource__card-image {
    width: $text_snippet_height;
    height: $text_snippet_height;
    background-color: $color-rol-magenta;
    background-size: cover;
    background-position: center;
    border-radius: 0.25rem;
    margin-right: 12px;
    flex-shrink: 0;
    background-color: $color-accent;

    .snippets__featured & {
        width: 100%;
        height: 128px; // 8rem with rem at 16px
        border-radius: 0.25rem 0.25rem 0 0;
    }
}

// Snippet extra info - course / resource label, course parts & bookmarks
.course-resource__parts-bookmarks {
    margin-top: $width-gap * 0.65;
    color: $color-lightest-readable-grey;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
        @extend %icon-svg;
        position: relative;
        top: 0.5px;
        fill: $color-lightest-readable-grey;
        fill-opacity: 0.6;
    }

    .snippets__featured & {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

.course-resource__label,
.course-resource__bookmarks {
    display: flex;
    align-items: center;
}

.course-resource__label {
    flex-wrap: wrap;

    span:not(.course-resource__divider) {
        padding: 0 0.5rem;
    }
}

.course-resource__bookmarks {
    svg {
        fill: #fff;
        stroke: $color-primary;
        stroke-width: 8px;
        transform: scale(0.85);
    }

    .is-bookmarked svg,
    &.is-bookmarked svg {
        fill: $color-primary;
        fill-opacity: 1;
    }

    a {
        // prevent bad alignment in course/resource snippets
        line-height: 0;
    }
}

.course-resource__divider {
    width: 0.1rem;
    height: 1rem;
    position: relative;
    padding: 0 0.025rem;

    &:after {
        content: '';
        display: block;
        position: relative;
        left: -2px;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        background-color: $color-lightest-readable-grey;
        border-radius: 4px;
    }
}
